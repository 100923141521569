/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect } from 'react';
import startsWith from 'lodash/startsWith';

// Utils
import { isGranted } from '../../../src/gate/PermissionsGate';
import SCOPES from '../../../src/gate/scopes';
import isAdmin from '../../../src/utils/isAdmin';
import getCookieObject from '../../../src/utils/cookie';
import openModal from '../../../src/utils/modal/openModal';
import getProductName from '../../../src/utils/product/getProductName';
import closeModal from '../../../src/utils/modal/closeModal';

// Hooks
import useCart from '../../../src/hooks/useCart';
import useRouter from '../../../src/hooks/useRouter';
import useTranslation from '../../../i18n/client';
import { useAppContext } from '../../../src/store/AppContext';

// Components
import Modal from '../Modal/Modal';
import Button from '../../atoms/Button/Button';
import ProductImage from '../ProductImage/ProductImage';

function MergeCartModal() {
  const { shouldCartBeReset, resetCart, unlinkOrderFromCart } = useCart();
  const [{ cart }] = useAppContext();
  const user = getCookieObject('user');
  const { pathname } = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAdmin() === false && !isGranted(user, [SCOPES.canLogInAsAdmin])) {
      if (shouldCartBeReset() && !startsWith(pathname, '/checkout')) {
        openModal('mergeCartModal');
      }
    }
  }, [pathname, shouldCartBeReset]);

  // TODO: When Order pages are refactored,
  // it could be nice to have a link to the client order associated with this orderId

  const handleResetCart = () => {
    resetCart();
    closeModal('mergeCartModal');
  };

  const handleMergeCart = () => {
    unlinkOrderFromCart();
    closeModal('mergeCartModal');
  };

  const isCartWithAssociatedOrder = cart && cart.orderId != null;

  const renderItems = () => {
    if (cart != null && cart.products != null) {
      return cart.products.map((product) => (
        <div key={product.id} className="flex a-stretch">
          <div style={{ width: '150px' }} className="m-r-20">
            <ProductImage product={product.product} category={product.category} smallBrand />
          </div>
          <div className="flex-auto flex d-col m-t-10">
            <h3 className="text-capitalize fs-18 ff-secondary fw-400 lh-light">
              {product ? getProductName(t, product.product, product.category) : null}
            </h3>
            <span className="fs-14 text-c-grey-600 m-b-0 m-t-10">
              {`${t('products.results.EANcode')} : ${product.product.ean}`}
            </span>
            <span className="fs-14 text-c-grey-600">
              {t('products.results.manufCode')} : {product.product.manufacturerProductCode}
            </span>
            <p className="fs-16 text-c-grey-600 m-t-10 text-inner-black">
              {`${t('cart.quantity')} : `}
              <span>{product.quantity}</span>
            </p>
          </div>
        </div>
      ));
    }
    return null;
  };

  return (
    <Modal
      id="mergeCartModal"
      title={t('cart.mergeCartModal.title')}
      buttons={
        <>
          <Button color="inverted" onClick={handleResetCart}>
            {t('cart.mergeCartModal.resetCart')}
          </Button>
          <Button onClick={handleMergeCart}>{t('cart.mergeCartModal.mergeCart')}</Button>
        </>
      }
    >
      <p>
        {isCartWithAssociatedOrder
          ? t('cart.mergeCartModal.explanationAlreadyOrdered')
          : t('cart.mergeCartModal.explanationNoOrder')}
      </p>
      <div>{renderItems()}</div>
    </Modal>
  );
}

export default MergeCartModal;
