export default function appReducer(action, state, clearAll, initStore, setItem, setSessionItem) {
  switch (action.type) {
    case 'RESET_STATE': {
      clearAll();
      return initStore;
    }

    case 'SET_USER': {
      setItem('user', action.payload);
      return { ...state, user: action.payload };
    }

    case 'SET_CART': {
      setItem('cart', action.payload);
      return { ...state, cart: action.payload };
    }

    case 'SET_NEW_ITEM_ADDED': {
      setItem('newItemAdded', action.payload);
      return { ...state, newItemAdded: action.payload };
    }

    case 'SET_BANNER_TYPE': {
      setItem('homeBannerType', action.payload);
      return { ...state, homeBannerType: action.payload };
    }

    case 'SET_CURRENT_VARIATION': {
      setItem('currentVariation', action.payload);
      return { ...state, currentVariation: action.payload };
    }

    case 'SET_CURRENT_SEARCH_PAGE': {
      setItem('currentSearchPage', action.payload);
      return { ...state, currentSearchPage: action.payload };
    }

    case 'SET_API_CACHE': {
      setItem('apiCached', action.payload);
      return { ...state, apiCached: action.payload };
    }

    case 'SET_IMPERSONATE_USER_TOKEN': {
      setItem('impersonateUser', action.payload);
      return { ...state, impersonateUser: action.payload };
    }

    case 'SET_ORDER_ID': {
      setItem('orderId', action.payload);
      return { ...state, orderId: action.payload };
    }

    case 'SET_KEY_ACCOUNT_CONTEXT': {
      setSessionItem('keyAccountContext', action.payload);
      return { ...state, keyAccountContext: action.payload };
    }

    case 'SET_KA_CONTEXT_RELOAD_CODE': {
      setSessionItem('kaContextReloadCode', action.payload);
      return { ...state, kaContextReloadCode: action.payload };
    }

    case 'SET_LOADING_CONTEXT': {
      setSessionItem('isLoadingContext', action.payload);
      return { ...state, isLoadingContext: action.payload };
    }

    case 'SET_IMPERSONATE_KEY_ACCOUNT_CONTEXT': {
      setSessionItem('impersonateKeyAccountContext', action.payload);
      return { ...state, impersonateKeyAccountContext: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
