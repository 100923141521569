var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"nBYodsUJYudHV5jxkmiH8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@spotlightjs/spotlight';
import initSentry from './common.sentry.config';

initSentry(
    {
        dsn: '___DSN___',
    },
);

if (process.env.NEXT_PUBLIC_SENTRY_ENV === 'development') {
    init(
        {
            anchor: 'bottomLeft',
            debug: true,
        },
    );
  }
